import { hot } from 'react-hot-loader/root';
import { Router } from './Router';
import { GameService, WebService, MetricsService } from 'services';
import * as Sentry from '@sentry/react';
import { IS_DEVELOPMENT } from 'shared/constants';


GameService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
GameService.prefix = 'gameflows';
WebService.setCredentials({ URL: process.env.REACT_APP_WEB_URL || '' });
MetricsService.setCredentials({ URL: process.env.REACT_APP_METRICS_URL || '' });

!IS_DEVELOPMENT && Sentry.init({
    dsn         : 'https://5afe3e26e239f96424ed6709a823460b@o4508301841793024.ingest.de.sentry.io/4508301847101520',
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [ 'localhost', /^https:\/\/stage-pmi-game\.alvs\.tech/, /^https:\/\/pmi-game\.alvs\.tech/ ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App = hot(Router);
